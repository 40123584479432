export default function createFreeTrialsDetail(data) {
  if (!data) {
    return null;
  }

  return {
    allowProofUrl: data.allow_proof_url,
    autoApproveIsAllowed: data.auto_approve_is_allowed,
    isAllowedWithSpendAbove: data.is_allowed_with_spend_above,
    minUserSpendAmount: data.min_user_spend_amount,
    minAmountForAutoApprove: data.min_amount_for_auto_approve,
    id: data.id,
    icon: data.icon,
    image: data.image,
    isArchived: data.is_archived,
    isEnabled: data.is_enabled,
    isRepeatable: data.is_repeatable,
    name: data.name,
    deadline: data.deadline,
    quantity: data.quantity,
    service: data.service,
    translations: data.translations,
    repeatsCount: data.repeats_count,
  };
}
