import RepositoryFactory from '@/services/repository-factory';

const externalPanelsRepository = RepositoryFactory.get('externalPanels');
const couponsRepository = RepositoryFactory.get('coupons');
const promotableCheckerGroupsRepository = RepositoryFactory.get('promotableCheckerGroups');
const aplicationRepository = RepositoryFactory.get('application');

export default {
  namespaced: true,
  state: {
    loading: false,

    locales: [],
    autoTranslation: [],

    externalPanelsCurrencies: [],
    externalPanelsStatisticTypes: [],

    paymentTypes: [],
    couponsBonusTypes: [],
    bonusAmountCalculationTypes: [],

    promotableCheckerGroups: [],

    userStatuses: [],
    orderStatuses: [
      {
        value: 'in_processing',
        label: 'In processing',
        color: 'primary white--text',
      },
      {
        value: 'in_moderation',
        label: 'In moderation',
        color: 'warning white--text',
      },
      {
        value: 'completed',
        label: 'Completed',
        color: 'success white--text',
      },
      {
        value: 'stopped',
        label: 'Stopped',
        color: 'warning white--text',
      },
      {
        value: 'canceled',
        label: 'Canceled',
        color: 'red white--text',
      },
      {
        value: 'unpaid',
        label: 'Unpaid',
        color: 'default',
      },
    ],
    orderSources: [
      {
        value: 'api',
        label: 'API',
      },
      {
        value: 'cabinet',
        label: 'Cabinet',
      },
      {
        value: 'order_widget',
        label: 'Order widget',
      },
      {
        value: 'socpanel',
        label: 'Panvy',
      },
      {
        value: 'perfect_panel_api',
        label: 'Perfect Panel API',
      },
      {
        value: 'subscription',
        label: 'Subscription',
      },
      {
        value: 'order_widget_multiple',
        label: 'Order widget multiple',
      },
      {
        value: 'buy_page',
        label: 'Buy page',
      },
      {
        value: 'single_form',
        label: 'Single form',
      },
      {
        value: 'drip_feed',
        label: 'Drip-feed',
      },
      {
        value: 'free_trial',
        label: 'Free trials',
      },
      {
        value: 'package',
        label: 'Package',
      },
      {
        value: 'order_widget_step_by_step',
        label: 'Order widget step by step',
      },
      {
        value: 'order_widget_mini',
        label: 'Order widget Mini',
      },
    ],
    freeTrialsStatuses: [
      {
        value: 'pending',
        label: 'Pending',
        color: 'warning white--text',
      },
      {
        value: 'rejected',
        label: 'Rejected',
        color: 'error white--text',
      },
      {
        value: 'approved',
        label: 'Approved',
        color: 'primary white--text',
      },
    ],
    externalPanelStatuses: [
      {
        value: 'Creating',
        color: 'blue-grey white--text',
      },
      {
        value: 'Pending',
        color: 'teal white--text',
      },
      {
        value: 'In progress',
        color: 'purple white--text',
      },
      {
        value: 'Processing',
        color: 'primary white--text',
      },
      {
        value: 'Completed',
        color: 'success white--text',
      },
      {
        value: 'Partial',
        color: 'warning white--text',
      },
      {
        value: 'Canceled',
        color: 'red white--text',
      },
    ],
    subscriptionsStatuses: [
      {
        value: 'in_processing',
        label: 'In processing',
        color: 'primary white--text',
      },
      {
        value: 'completed',
        label: 'Completed',
        color: 'success white--text',
      },
      {
        value: 'canceled',
        label: 'Canceled',
        color: 'red white--text',
      },
    ],
    dripFeedStatuses: [
      {
        value: 'in_processing',
        label: 'In processing',
        color: 'primary white--text',
      },
      {
        value: 'completed',
        label: 'Completed',
        color: 'success white--text',
      },
      {
        value: 'canceled',
        label: 'Canceled',
        color: 'red white--text',
      },
    ],
    withdrawalsStatuses: [
      {
        value: 'pending',
        label: 'Pending',
        color: 'orange accent-3 white--text',
      },
      {
        value: 'approved',
        label: 'Approved',
        color: 'deep-purple accent-2 white--text',
      },
      {
        value: 'paid',
        label: 'Paid',
        color: 'green accent-4 white--text',
      },
      {
        value: 'rejected',
        label: 'Rejected',
        color: 'deep-orange darken-4 white--text',
      },
    ],
    serviceFullDescriptionLocationOptions: ['header', 'footer'],
    balanceTypes: [
      {
        label: 'All',
        type: 'all',
      },
      {
        label: 'Main balance',
        caption: 'Balance',
        type: 'main',
        balance: 'balance',
      },
      {
        label: 'Tasks balance',
        caption: 'Tasks balance',
        type: 'task',
        balance: 'taskUserBalance',
      },
      {
        label: 'Referral balance',
        caption: 'Referral program balance',
        type: 'referral',
        balance: 'referralUserBalance',
      },
    ],
    externalPanelTypes: [
      {
        value: 'socpanel',
        label: 'Panvy',
      },
      {
        value: 'perfect_panel',
        label: 'Perfect panel',
      },
      {
        value: 'social_value',
        label: 'Social value',
      },
    ],
    priceModificationsTypes: {
      coupon: {
        label: 'Coupon',
        icon: 'mdi-ticket-percent',
        color: 'orange darken-1',
      },
      discount: {
        label: 'Discount',
        icon: 'mdi-sale',
        color: 'red accent-2',
      },
      userService: {
        label: 'Custom price',
        icon: 'mdi-tag',
        color: 'light-blue accent-3',
      },
      freeTrial: {
        label: 'Free trial',
        icon: 'mdi-gift',
        color: 'light-blue accent-3',
      },
    },
  },
  mutations: {
    SET_LOADING: (state, value) => { state.loading = value; },
    SET_LOCALES: (state, value) => { state.locales = value; },
    SET_AUTO_TRANSLATION: (state, value) => { state.autoTranslation = value; },
    SET_EXTERNAL_PANELS_CURRENCIES(state, value) {
      state.externalPanelsCurrencies = value;
    },
    SET_EXTERNAL_PANELS_STATISTIC_TYPES(state, value) {
      state.externalPanelsStatisticTypes = value;
    },
    SET_PAYMENT_TYPES(state, value) {
      state.paymentTypes = value;
    },
    SET_COUPONS_BONUS_TYPES(state, value) {
      state.couponsBonusTypes = value;
    },
    SET_BONUS_AMOUNT_CALCULATION_TYPES(state, value) {
      state.bonusAmountCalculationTypes = value;
    },
    SET_PROMOTABLE_CHECKER_GROUPS(state, value) {
      state.promotableCheckerGroups = value;
    },
    SET_USER_STATUSES(state, value) {
      state.userStatuses = value;
    },
  },
  actions: {
    fetch({
      dispatch, commit,
    }) {
      commit('SET_LOADING', true);

      return Promise.all([
        dispatch('getExternalPanelsCollections'),
        dispatch('getCouponCollections'),
        dispatch('getPromotableCheckerGroups'),
        dispatch('getApplications'),
      ])
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    getExternalPanelsCollections({ commit }) {
      return externalPanelsRepository.collections()
        .then(({
          currencies,
          statisticTypes,
        }) => {
          commit('SET_EXTERNAL_PANELS_CURRENCIES', currencies);
          commit('SET_EXTERNAL_PANELS_STATISTIC_TYPES', statisticTypes);
        });
    },
    getCouponCollections({ commit }) {
      return couponsRepository.collections()
        .then(({
          paymentTypes,
          couponsBonusTypes,
          bonusAmountCalculationTypes,
        }) => {
          commit('SET_PAYMENT_TYPES', paymentTypes);
          commit('SET_COUPONS_BONUS_TYPES', couponsBonusTypes);
          commit('SET_BONUS_AMOUNT_CALCULATION_TYPES', bonusAmountCalculationTypes);
        });
    },
    getPromotableCheckerGroups({ commit }) {
      return promotableCheckerGroupsRepository.index()
        .then((promotableCheckerGroups) => {
          commit('SET_PROMOTABLE_CHECKER_GROUPS', promotableCheckerGroups);
        });
    },
    getApplications({ commit }) {
      return aplicationRepository.index()
        .then((aplications) => {
          commit('SET_USER_STATUSES', aplications.user);
          commit('SET_LOCALES', aplications.locales);
          commit('SET_AUTO_TRANSLATION', aplications.autoTranslation);
        });
    },
  },

  getters: {
    localesKeyArray: (state) => Object.keys(state.locales),
    localesValuesArray: (state) => Object.values(state.locales),
    mainBalance: (state) => state.balanceTypes.find((balance) => balance.type === 'main'),
    taskBalance: (state) => state.balanceTypes.find((balance) => balance.type === 'task'),
    referralBalance: (state) => state.balanceTypes.find((balance) => balance.type === 'referral'),
    objectOrderSources: (state) => state.orderSources.reduce((acc, orderSource) => {
      acc[orderSource.value] = orderSource.label;

      return acc;
    }, {}),
    objectExternalPanelTypes: (state) => state.externalPanelTypes.reduce((acc, panelType) => {
      acc[panelType.value] = panelType.label;

      return acc;
    }, {}),
    withdrawalsStatuses: (state) => state.withdrawalsStatuses.reduce((acc, status) => {
      acc[status.value] = status;

      return acc;
    }, {}),
    withdrawalsStatusesLabels: (state) => state.withdrawalsStatuses
      .map((status) => status.label),
    priceModificationsTypes: (state) => state.priceModificationsTypes,
    userStatuses: (state) => Object.values(state.userStatuses),
  },
};
