import router from '@/router';
import settings from '@/settings';
import invokeDialog from '@/components/services';
import {
  createCellActions, createCellAffiliates, createCellLink, createCellPlainStatuses, createCellMoney,
} from '../builder-definition/tables';
import {
  createCellBool, createCellCopy, createCellSellerStatus,
} from '../builder-definition/tables/plain-builders';
import {
  createDateFilter,
  createEnabledFilter,
  createTextFilter,
  createGradesFilter,
} from '../builder-definition/filters/table';

const config = {
  headers: [
    {
      text: 'ID',
      value: 'id',
      name: 'users.id',
      hideable: false,
      isShown: true,
      isShowedStatus: 'always',
      ...createCellPlainStatuses({
        mapper: 'user',
        statusProp: 'status',
      }),
      width: '65px',
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
    },
    {
      text: 'SubID',
      value: 'marketingSubid',
      name: 'marketing_subid',
      sortable: false,
      isShown: false,
      width: '150px',
      customFilter: createTextFilter({ placeholder: 'Search Subid' }),
    },
    {
      text: 'Name',
      value: 'name',
      width: '120px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search Name' }),
      ...createCellLink({
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.id,
          },
        }),
      }),
    },
    {
      text: 'Email',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search Email' }),
      value: 'email',
      ...createCellCopy(),
      width: '153px',
    },
    {
      text: 'Telegram',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search Telegram' }),
      value: 'telegramUsername',
      name: 'telegram_username',
      ...createCellCopy(),
      width: '153px',
    },

    {
      text: 'Balance',
      value: 'balance',
      isShown: true,
      width: '100px',
      ...createCellMoney(),
    },
    {
      text: 'Spent',
      value: 'spent',
      width: '74px',
      ...createCellMoney(),
      isShown: true,
    },
    {
      text: 'User’s grade',
      value: 'currentGrade.name',
      name: 'grade',
      width: '115px',
      isShown: true,
      sortable: false,
      customFilter: createGradesFilter(),
      permission: settings.permissions.viewGrades,
    },
    {
      text: 'Messengers',
      value: 'messengers.length',
      width: '90px',
      isShown: true,
      name: 'has_messengers',
      sortable: false,
      ...createCellBool(),
      customFilter: createEnabledFilter(),
    },
    {
      text: 'Custom prices',
      value: 'userServiceExists',
      width: '90px',
      isShown: true,
      name: 'user_service_exists',
      sortable: false,
      customFilter: createEnabledFilter(),
      ...createCellBool(),
    },
    {
      value: 'createdAt',
      text: 'Date',
      isShown: true,
      name: 'users.created_at',
      width: '110px',
      customFilter: createDateFilter(),
    },
    {
      text: 'Affiliate code',
      value: 'affiliateCode',
      name: 'affiliate_code',
      customFilter: createTextFilter({ placeholder: 'Search Affiliate code' }),
      sortable: false,
      width: '147',
    },
    {
      text: 'Affiliates',
      value: 'affiliates',
      name: 'affiliate',
      sortable: false,
      width: '177',
      customFilter: createTextFilter({ placeholder: 'Search Affiliates' }),
      ...createCellAffiliates({
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.id,
          },
        }),
      }),
    },
    {
      text: 'Manager',
      value: 'seller.email',
      name: 'seller.email',
      sortable: false,
      width: '177',
      customFilter: createTextFilter({ placeholder: 'Search Manager' }),
    },
    {
      text: 'Seller status',
      value: 'sellerStatus',
      name: 'seller_status',
      sortable: false,
      ...createCellSellerStatus(),
      width: '177',
    },

    {
      text: 'Affiliate',
      value: 'affiliate',
      isHidden: true,
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      searchable: false,
      isShown: true,
      sortable: false,
      width: '38px',
      ...createCellActions({
        actionItems: (tableElement, contextStore) => {
          const defaultActions = [
            {
              text: 'Edit',
              value: 'edit',
              prop: 'editable',
              permission: 'view users',
              callBack: (item) => {
                router.push({
                  name: 'ProfileUser',
                  params: {
                    id: item.id,
                  },
                });
              },
            },
            {
              text: 'Freeze',
              value: 'freeze',
              permission: 'freeze users',
              callBack: (item, store) => {
                invokeDialog({
                  confirmCallback: () => {
                    store.dispatch('users/freezeUser', item.id);
                  },
                }, 'freezeUser');
              },
            },
            {
              text: 'Deactivate',
              value: 'deactivate',
              permission: 'deactivate users',
              callBack: (item, store) => {
                invokeDialog({
                  confirmCallback: () => {
                    store.dispatch('users/deactivateUser', item.id);
                  },
                }, 'deactivateUser');
              },
            },
            {
              text: 'Activate',
              value: 'activate',
              permission: 'activate users',
              callBack: (item, store) => {
                invokeDialog({
                  confirmCallback: () => {
                    store.dispatch('users/activateUser', item.id);
                  },
                }, 'activateUser');
              },
            },
          ];
          const excluded = [];

          if (tableElement.deactivatedAt) {
            excluded.push('deactivate');
          }

          if (tableElement.frozenAt) {
            excluded.push('freeze');
          }

          if (!tableElement.frozenAt && !tableElement.deactivatedAt) {
            excluded.push('activate');
          }

          defaultActions.forEach((action) => {
            if (action.permission && !contextStore.getters['auth/checkPermission'](action.permission)) {
              excluded.push(action.value);
            }
          });

          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;
            if (tableElement.actions[prop]) return true;

            return false;
          });

          return filteredActions.filter(({ value }) => !excluded.includes(value));
        },
      }),
    },
  ],
};

export default config;
